import { navigate } from "gatsby"
import { isBrowser } from "../../../services/helper"

const AccountHelp = () => {
    if(isBrowser()) {
        navigate(`/help`, { replace: true})
    }
    return null
}

export default AccountHelp